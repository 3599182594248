import type { loader as rootLoader } from "@/root";
import type { loader as appLoader } from "@/routes/app+/_layout";
import type { loader as studentLayoutLoader } from "@/routes/app+/students.$studentId+/_layout";
import type { SerializeFrom } from "@remix-run/node";
import { useMatches, useRouteLoaderData } from "@remix-run/react";

export function useRootData() {
	const data = useRouteLoaderData<typeof rootLoader>("root");
	if (!data) throw new Error("Root data not found");
	return data;
}

export function useAppData() {
	const matches = useMatches();
	const appData = matches.find((data) => data.pathname === "/app")
		?.data as SerializeFrom<typeof appLoader>;

	if (!appData) throw new Error("App data not found");

	return appData;
}

export function useAppStudentLayoutData() {
	const matches = useMatches();
	const appData = matches.find(
		(data) => data.id === "routes/app+/students.$studentId+/_layout",
	)?.data as SerializeFrom<typeof studentLayoutLoader>;

	if (!appData) throw new Error("App data not found");

	return appData;
}
